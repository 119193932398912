<template>
  <div class="sys-user">
    <page-title :title="'系统用户管理'">管理系统用户</page-title>
    <div class="user-sel">
        <el-input size="small" placeholder="请输入用户名检索" v-model="selCon.username" style="width:280px;margin-right:10px;"></el-input>
        <el-button icon="el-icon-search" type="primary" size="small" @click="userSel">查询</el-button>
    </div>
    <el-button icon="el-icon-plus" type="success" size="small" @click="userAddDialog=true">新增用户</el-button>
    <div class="user-table">
      <el-table :data="tableData" size="mini" border style="width: 100%">
        <el-table-column type="index" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="name" label="真实姓名" width="100" align="center">
        </el-table-column>
        <el-table-column prop="userSex" label="性别" width="60" align="center">
        </el-table-column>
        <el-table-column prop="userType" label="用户身份" width="100" align="center">
        </el-table-column>
        <el-table-column prop="username" label="登录账号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="roleName" label="角色" width="100" align="center">
        </el-table-column>
        <el-table-column prop="userTel" label="联系方式" width="120" align="center">
        </el-table-column>
        <el-table-column prop="userDept" label="所属单位" width="150" align="center">
        </el-table-column>
        <el-table-column label="用户状态" width="80" align="center">
          <template slot-scope="scope">
            <span :style="{color:scope.row.status=='启用'?'green':'red'}">{{scope.row.status}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建日期" width="160" align="center">
        </el-table-column>
        <el-table-column prop="userDesc" label="擅长领域" show-overflow-tooltip header-align="center"></el-table-column>
        <el-table-column label="操作" header-align="center" min-width="260" width="280" align="center">
            <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="resetPass(scope.row)">重置密码</el-button>
                <el-button size="mini" type="warning" @click="editUser(scope.row)">修改</el-button>
                <el-button size="mini" type="danger" @click="deleteUser(scope.row.id)">删除</el-button>
                <el-button size="mini" @click="lockUser">锁定</el-button>
            </template>
        </el-table-column>
      </el-table>
        <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="1000"
            style="margin-top:15px;text-align: right;">
        </el-pagination>
    </div>
    <el-dialog title="新增用户" :visible.sync="userAddDialog" custom-class="my-dialog-style" @close="resetForm">
      <el-form ref="form" :model="form" size="small" label-width="100px" label-position="right">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="用户名：" prop="username">
              <el-input v-model="form.username" placeholder="请输入登录账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码：" prop="password">
              <el-input v-model="form.password" type="password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="真实姓名：" prop="name">
              <el-input v-model="form.name" placeholder="请输入用户姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-col :span="12">
            <el-form-item label="用户性别：" prop="userSex">
              <el-radio-group v-model="form.userSex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="手机号：" prop="userTel">
              <el-input v-model="form.userTel" placeholder="请输入用户联系方式"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱：" prop="userEmail">
              <el-input v-model="form.userEmail" placeholder="请输入用户邮箱"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="所属单位：" prop="userUnit">
              <el-input v-model="form.userUnit" placeholder="请输入用户联系方式"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门：" prop="userDept">
              <el-input v-model="form.userDept" placeholder="请输入用户邮箱"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="用户身份：" prop="userType">
              <el-radio-group v-model="form.userType">
                <el-radio label="00">管理员</el-radio>
                <el-radio label="04">维修组长</el-radio>
                <el-radio label="03">维修工</el-radio>
                <el-radio label="02">内勤</el-radio>
                <el-radio label="01">领导</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="启用状态：" prop="status">
              <el-radio-group v-model="form.status">
                <el-radio label="启用">启用</el-radio>
                <el-radio label="禁用">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户角色：" prop="roleId">
              <el-select v-model="form.roleId" placeholder="请选择用户角色" style="width:100%;" @change="roleChange">
                <el-option :label="item.name" :value="item.id" v-for="item in roleData" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="擅长领域：" prop="userDesc">
              <el-input v-model="form.userDesc" type="textarea" rows="3" placeholder="请输入擅长领域"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userAddDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveUser" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "User",
  data() {
    return {
      tableData: [],
      roleData:[],
      userAddDialog:false,
      form: {
        id:null,
        username: '',
        password:'',
        name:'',
        userTel:'',
        userUnit:'',
        userDept:'',
        userEmail:'',
        userSex:'',
        userType:'管理员',
        roleName:'',
        roleId:'',
        status:'启用',
        userDesc: ''
      },
      selCon:{
        username:"",
        name:""
      }
    };
  },
  created(){
    this.initPage();
    this.initRoleList();
  },
  methods:{
    /**初始加载页面数据 */
    initPage(){
      const that = this;
      that.request.get("sysuser/page").then(res=>{
          that.tableData = res.data;
      })
    },
    /**初始化角色数据 */
    initRoleList(){
      const that = this;
      that.request.get("sysrole/list").then(res=>{
          that.roleData = res.data;
      })
    },
    /**表单中角色选择,赋值表单中的字段 */
    roleChange(e){ 
      const that = this;
      let role = that.roleData.find(item=> item.id === e);
      that.form.roleName = role.name;
    },
    /**保存用户数据 */
    saveUser(){
      console.log(this.form);
      const that  = this;
      that.request.post("sysuser/save",that.form).then(res=>{
        if(res.code===200){
          that.userAddDialog = false;
          that.initPage();
          that.$refs['form'].resetFields();
        }
      });
    },
    /**重置密码 */
    resetPass(obj){
      console.log(obj);
    },
    editUser(row){
      const that = this;
      that.userAddDialog = true;
      that.$nextTick(()=>{
        //这里赋值回显数据,会异步给表单进行赋值
        //异步即待表单dom渲染完成
        //之后既可以直接使用form的reset方法置空表单，恢复到初始值。
        that.form = JSON.parse(JSON.stringify(row));
      });
    },
    /**用户删除操作 */
    deleteUser(id){
      const that = this;
      that.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          that.request.get("sysuser/delete",{id:id}).then(res=>{
              if(res.code===200){
                  that.initPage();
              }
          });
      }).catch({});
    },
    /**锁定或者解锁用户 */
    lockUser(){

    },
    /**条件检索 */
    userSel(){
      console.log(this.selCon);
    },
    /**弹窗关闭后初始化表单数据 */
    resetForm(){
      this.$refs['form'].resetFields();
      this.form.id=null;
    }
  }
};
</script>
<style lang="less" scoped>
.sys-user{
    .user-sel{
        display: flex;
        margin-bottom: 15px;
    }
    .user-table{
        margin-top: 15px;
    }
}
</style>